import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`La farinera borda té un capell inicialment acampanat i després pla de 6-14 cm de diàmetre amb una superfície llisa de color que varia de groc llimona, blanc grogós, a verd oliva obscur i fins i tot blanc. En general no presenta esquames o plaques esquamoses blanques, encara que poden estar als exemplars més joves però desapareixen ràpidament. Les làmines són blanques, lliures, juntes i amples. El peu de 8-17 x 1-2 cm és blanquinós o amb tonalitats verdoses, cilíndric i presenta un anell membranós, persistent, caigut i amb estries ben marcades. La volva és blanca, membranosa, persistent i envolta el peu com si es tractés d’un petit sac. Les espores són blanques en massa, ovoides, de 8-11 x 7-9 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      